import {
  Button,
  ContentContainer,
  LoadingSpinner,
  Popup,
} from '@components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '@components/App'
import { axiosInstance } from '@/methods/axiosConfig'
import { actionReport } from '@/methods/actionReport'
import { devLog } from '@/methods/devLog'
import { SignDocumentSingle } from '@components/SignDocument/SignDocumentSingle'
import { SignDocumentMultiple } from '@components/SignDocument/SignDocumentMultiple'
import { ScrollDocumentPopupBody, Spacer } from '@styles/generic.styles'
import { ContentToRight } from '@styles/layout.styles'
import { Document } from '@components/SignDocument/types'
import { apiUrl } from '@/constants/apiUrl'
import { SignDocumentPageV2 } from '@/components/SignDocument/SignDocumentPageV2'

export const SignDocumentPage = observer(() => {
  const store = useContext(StoreContext)
  const { signDocumentPage: trans } = store.TranslationsState.translations

  if (!window.location.href.includes('onboarding-v3')) {
    return <SignDocumentPageV2 trans={trans} />
  }

  const { theme } = store.InterfaceState
  const { currentScenarioId, context } = store.ScenarioState

  const [isLoading, setIsLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)

  const [signed, setSigned] = useState(false)
  const [singleDoc, setSingleDoc] = useState<{
    url: string
    id: string
  }>()
  const [docs, setDocs] = useState<Document[]>([])
  const [isDocReady, setIsDocReady] = useState(false)

  const [containerHeight, setContainerHeight] = useState('')

  const [infoPopupVisible, setInfoPopupVisible] = useState(
    context?.forceScrollingBeforeDocumentAccept
  )

  const getDocument = async () => {
    setIsLoading(true)
    try {
      const res = await axiosInstance.get<Document[]>(
        `${apiUrl}/electronic-id/list-of-documents`,
        {
          withCredentials: true,
        }
      )
      devLog(res.data)

      if (res.data.length === 1) {
        const { data } = await axiosInstance.get<{ signedUrl: string }>(
          `/electronic-id/pdf-document-presigned`,
          {
            withCredentials: true,
          }
        )

        setSingleDoc({
          url: data.signedUrl,
          id: res.data[0].fileId,
        })
      } else if (res.data.length > 1) {
        setDocs(res.data)
      }

      setIsDocReady(true)

      actionReport({
        type: 'event.onboarding-web.agreement.AGREEMENT_ACKNOWLEDGED',
        payload: {},
      })
    } catch (e) {
      devLog(e)
    }
    setIsLoading(false)
  }

  const handleSign = async (docId: string): Promise<boolean> => {
    try {
      if (currentScenarioId !== 'localTests') {
        setBtnLoading(true)
        const apiUrl = process.env.WEB_API_URL

        await axiosInstance.post(
          `${apiUrl}/electronic-id/accept-document-to-be-signed`,
          {
            fileId: docId,
          },
          {
            withCredentials: true,
          }
        )

        setBtnLoading(false)
      }

      return true
    } catch (e) {
      devLog(e)
      setBtnLoading(false)
      return false
    }
  }

  useEffect(() => {
    getDocument()
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="1000px"
      minHeight="250px"
      paddingMobile="20px"
      heightMobile={containerHeight}
      marginMobile="0px"
    >
      {isLoading || !isDocReady ? (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      ) : (
        <>
          {singleDoc?.url?.length > 0 && (
            <SignDocumentSingle
              signed={signed}
              setSigned={setSigned}
              doc={singleDoc}
              handleSign={handleSign}
              btnLoading={btnLoading}
              setContainerHeight={setContainerHeight}
              trans={trans}
            />
          )}

          {docs?.length > 0 && (
            <SignDocumentMultiple
              signed={signed}
              setSigned={setSigned}
              docs={docs}
              handleSign={handleSign}
              btnLoading={btnLoading}
              setContainerHeight={setContainerHeight}
              trans={trans}
            />
          )}
        </>
      )}

      <Popup
        visibility={infoPopupVisible}
        handleVisibility={setInfoPopupVisible}
        {...theme.popup}
        width="420px"
        mobileWidth="324px"
        height="auto"
        mobileHeight="auto"
      >
        <ScrollDocumentPopupBody>
          {trans.popupDescription}
        </ScrollDocumentPopupBody>
        <Spacer height="20px" />
        <ContentToRight>
          <Button
            onClick={() => setInfoPopupVisible(false)}
            {...theme.button}
            margin="20px 0 0"
          >
            {trans.popupButton}
          </Button>
        </ContentToRight>
      </Popup>
    </ContentContainer>
  )
})
